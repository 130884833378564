#root {
  max-width: 1280px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}

body {
  background-color: #141414; 
  margin: 0; 
  padding: 0;
  height: 100%;
  /* cursor: none; */
  width: 100%;
}

html {
  scroll-behavior: smooth;
}

html, body, #root {
  height: 100%; /* Ensure body takes the full height of the page */
}

.gradient-text {
  background-image: linear-gradient(to bottom, #FFFFFF, #018f27);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.box-shadow-fade {
  box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.9);
  pointer-events: none; 
}

@keyframes scroll-slow {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-70%);
  }
}

@keyframes scroll-medium {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(50%); /* Positive value for scrolling downwards */
  }
}

@keyframes scroll-fast {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(50%); /* Positive value for scrolling downwards */
  }
}


.animate-scroll-slow {
  animation: scroll-slow 30s linear infinite;
}

.animate-scroll-medium {
  animation: scroll-medium 15s linear infinite;
}

.animate-scroll-fast {
  animation: scroll-fast 10s linear infinite;
}


